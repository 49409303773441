/*------------------------------------
  Comments
------------------------------------*/
.comment-list {
  padding-left: 0;
  li {
    list-style: none;
    margin-top: $comment-list-top;
    .user-comment {
      border-bottom:  var(--#{$prefix}gray-200) 1px dashed;
      padding-bottom: $comment-article-p-bottom;
      .comment-author {
        .avatar {
          float: left;
          margin-right: 1rem;
        }
      }
      .title-comments {
        .authors {
          font-weight: $font-weight-bold;
        }
      }
      .comment-content {
        p {
          margin-bottom: $comment-p;
        }
      }
    }
    .sub-comment {
      margin-left: $subcomment-margin-left;
      .sub-comment {
        margin-left: $sub-subcomment-mobile-margin-left;
      }
    }
  }
}
.comment-awaiting-moderation {
    background: var(--#{$prefix}yellow);
}
@include media-breakpoint-up(sm) {
  .comment-list {
    li{
      .sub-comment {
        .sub-comment {
          margin-left: $sub-subcomment-margin-left;
        }
      }
    }
    .title-comments {
      .dates {
        float: right;
      }
    }
  }
}