/*------------------------------------
   Animated
------------------------------------*/
.animated-up-down {
  animation: up-down 1.5s ease-in-out infinite alternate-reverse both;
  -webkit-animation: up-down 1.5s ease-in-out infinite alternate-reverse both; 
}
@keyframes up-down {
  0% { 
    transform: translateY(0); 
    -webkit-transform: translateY(0); 
  }
  100% { 
    transform: translateY(-10px); 
    -webkit-transform: translateY(-10px); 
  }
}
.icon-center {
  &:hover {
    .play-btn-hover {
      -webkit-animation: animate 2s linear infinite;
      animation: animate 2s linear infinite;
    }
  }
}
@keyframes animate{
  0%{
    box-shadow: 0 0 0 0 rgba(228, 228, 228, 0.7);
  }
  40%{
    box-shadow: 0 0 0 50px rgba(224, 224, 224, 0);
  }
  80%{
    box-shadow: 0 0 0 50px rgba(206, 206, 206, 0);
  }
  100%{
    box-shadow: 0 0 0 rgba(199, 199, 199, 0);
  }
}

//collapse chevron icon
.collapse{
  &-arrow-end {
    transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    transition: transform 1s;
    -webkit-transition: transform 1s;
  }
  &-plus-end {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transition: transform 1s;
    -webkit-transition: transform 1s;
  }
}
.collapsed {
  .collapse-arrow-end, .collapse-plus-end {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
}

// Particle move up
.particle-move-up{
  position: absolute;
  display: block;
  list-style: none;
  bottom: -150px;
  &-1,&-2,&-3,&-4,&-5,&-6,&-7,&-8,&-9 {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }
  &-1 {
    left: 25%;
    animation-name: moveups;
    animation-delay: 0s;
    animation-duration: 45s;
  }
  &-2 {
    left: 10%;
    animation-name: moveups;
    animation-delay: 2s;
    animation-duration: 32s;
  }
  &-3 {
    left: 70%;
    animation-name: moveups;
    animation-delay: 4s;
    animation-duration: 36s;
  }
  &-4 {
    left: 40%;
    animation-name: moveups;
    animation-delay: 0s;
    animation-duration: 27s;
  }
  &-5 {
    left: 65%;
    animation-name: moveups;
    animation-delay: 0s;
    animation-duration: 38s;
  }
  &-6 {
    left: 75%;
    animation-name: moveups;
    animation-delay: 3s;
    animation-duration: 25s;
  }
  &-7 {
    left: 35%;
    animation-name: moveups;
    animation-delay: 7s;
    animation-duration: 25s;
  }
  &-8 {
    left: 50%;
    animation-name: moveups;
    animation-delay: 15s;
    animation-duration: 45s;
  }
  &-9 {
    right: 15%;
    animation-name: moveups;
    animation-delay: 2s;
    animation-duration: 35s;
  }
}
@keyframes moveups {
  0%{
    transform: translateY(0);
    opacity: 1;
    border-radius: 0;
  }
  100%{
    transform: translateY(-1000px);
    opacity: 0;
    border-radius: 50%;
  }
}