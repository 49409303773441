/*----------------------------------
  Tabs
------------------------------------*/
.tab-profile {
  &>li {
    &>a {
      &>div {
        background: var(--#{$prefix}light-dark);
      }
      &.active {
        &>div {
          background: var(--#{$prefix}white-black);
        }
      }
    }
  }
}