/*------------------------------------
    Filter
------------------------------------*/
.filter-label {
  display: block;
  padding: 0;
  margin-top: 0;
  margin-bottom: 4px;
  color: #95a5a6;
}

.filters-group {
  padding: 0;
  margin: 0 0 4px;
  border: 0;
}

@media screen and (min-width: 768px) {
  .filters-group-wrap {
    display: flex;
    justify-content: space-between;
  }
}