/*------------------------------------
  Preloader
------------------------------------*/
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
}
.loaded-success {
    .preloader {
        visibility: hidden;
        transition: all 1s ease-in-out;
    }
}
