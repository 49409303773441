/*----------------------------------
  Avatars
------------------------------------*/
.avatar {
	&-xs {
		width: $avatar-xs-width;
		height: $avatar-xs-height;
	}
	&-sm {
		width: $avatar-sm-width;
		height: $avatar-sm-height;
	}
	&-md {
		width: $avatar-md-width;
		height: $avatar-md-height;
	}
	&-lg {
		width: $avatar-lg-width;
		height: $avatar-lg-height;
	}
	&-xl {
		width: $avatar-xl-width;
		height: $avatar-xl-height;
	}
	&-group {
		img {
			margin-right: $avatar-group-margin-right;
			&:hover {
				margin-top: $avatar-group-margin-hover-top;
			}
		}
	}
}