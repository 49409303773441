/*------------------------------------
  Web Space
------------------------------------*/
.start {
  &-1 {
    left: 1rem;
  }
}
.end {
  &-0 {
    right: 0;
  }
  &-1 {
    right: 1rem;
  }
}
.top {
  &-1 {
    top: 1rem;
  }
}
.bottom{
  &-0 {
    bottom: 0;
  }
  &-1 {
    bottom: 1rem;
  }
}
.w-150 {
  width: 150%;
}