/*------------------------------------
  Blog
------------------------------------*/
.blog {
  // style 1
  &-one {
    &-img {
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        transform: scale(1);
        transition: all .5s ease-in-out 0s;
      }
    }
    &-category {
      position: absolute;
      bottom: 0;
      left: -50%;
      transition: all .5s ease-in-out 0s;
    }
    &:hover {
      img {
        transform: scale(1.2);
      }
      .blog-one-category {
        left: 0;
      }
    }
    .card-body {
      padding: $p-blog-card;
    }
  }
  // style 2
  &-two {
    &-img {
      position: relative;
      overflow: hidden;
      a {
        &:before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          background: rgba(0,0,0,.2);
          transition: opacity .4s linear 0s;
        }
      }
      img {
        width: 100%;
      }
    }
    &-category {
      position: absolute;
      bottom: 0;
      left: -50%;
      transition: all .5s ease-in-out 0s;
    }
    .blog-author {
      position: absolute;
      right: $blog-author-right;
      margin-top: $blog-margin-top;
      img {
        border: $white 3px solid;
      }
    }
    &:hover {
      .blog-two-img {
        a {
          &:before {
            opacity: 1;
          }
        }
      }
      .blog-one-category {
        left: 0;
      }
    }
    .card-body {
      padding: $p-blog-card;
    }
  }
  // style 3
  &-three {
    &-img {
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        transform: scale(1);
        transition: all .5s ease-in-out 0s;
      }
    }
    .post-date {
      position: absolute;
      text-align: center;
      width: $blog-post-date-width;
      top: 0;
      right: 0;
      transition: all .5s ease-in-out 0s;
      .date {
        font-size: $blog-post-date-font-size;
      }
    }
    &:hover {
      img {
        transform: scale(1.2);
      }
      .blog-one-category {
        left: 0;
      }
    }
    .card-body {
      padding: $p-blog-card;
    }
  }
}