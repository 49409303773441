/*------------------------------------
  Z index
------------------------------------*/
.z-index{
  &-n100 {
    z-index: -100;
  }
  &-n10 {
    z-index: -10;
  }
  &-n5 {
    z-index: -5;
  }
  &-n4 {
    z-index: -4;
  }
  &-n3 {
    z-index: -3;
  }
  &-n2 {
    z-index: -2;
  }
  &-n1 {
    z-index: -1;
  }
  &-1 {
    z-index: 1;
  }
  &-2 {
    z-index: 2;
  }
  &-3 {
    z-index: 3;
  }
  &-4 {
    z-index: 4;
  }
  &-5 {
    z-index: 5;
  }
  &-10 {
    z-index: 10;
  }
  &-20 {
    z-index: 20;
  }
  &-50 {
    z-index: 50;
  }
  &-100 {
    z-index: 100;
  }
}
// z-index lightgallery
.lg{
  &-backdrop {
    z-index: 1100;
  }
  &-outer {
    z-index: 1200;
  }
}