/*------------------------------------
  General
------------------------------------*/
html {
  overflow-x: hidden;
}
body {
  position: relative;
  overflow-x: hidden;
  font: {
    family: $font-primary;
  }
}
// pading top if nav fixed available
.body-nav-fixed {
  padding-top: $header-height;
}
section, .section {
  position:relative;
  z-index: 1;
}
a {
  color: var(--#{$prefix}link-color);
  text-decoration: none;
  &:hover {
    color: var(--#{$prefix}link-color);
  }
  &:focus {
    outline: none;
  }
}
button {
  &:focus {
    outline: none;
  }
}
.btn-link {
  text-decoration: none;
}
p {
  line-height: $line-height-base;
}
section, .section {
  p {
     line-height: $paragraph-line-height;
  }
}
.lead {
  margin-bottom: $lead-margin-bottom;
}
:focus { outline: none; }
figure {
  margin-bottom: 0;
}
hr {
  border-color: var(--#{$prefix}gray-100);
}
[role=button] {
  cursor: pointer;
}
.font-accent {
  font-family: "Open Sans", Helvetica, sans-serif;
}

/*------------------------------------
  Headings
------------------------------------*/
.h1, .h2, .h3, .h4, .h5, .h6, .h7,
h1, h2, h3, h4, h5, h6 {
  line-height: 1.3;
  color: var(--#{$prefix}heading-color);
  a {
    color: var(--#{$prefix}heading-color);
    &:hover {
      --bs-bg-opacity: 1;
			color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
    }
  }
}

/*------------------------------------
  Highlight Color
------------------------------------*/
::-moz-selection {
  color: var(--#{$prefix}white);
  background-color: var(--#{$prefix}primary);
}
::selection {
  color: var(--#{$prefix}white);
  background-color: var(--#{$prefix}primary);
}
.bg-primary ::-moz-selection {
  color: var(--#{$prefix}primary);
  background-color: var(--#{$prefix}white);
}
.bg-primary ::selection {
  color: var(--#{$prefix}primary);
  background-color: var(--#{$prefix}white);
}
.overflow-x-hidden {
  overflow-x: hidden;
}
/*------------------------------------
  hr divider
------------------------------------*/
hr {
  &.divider {
    margin-left: auto;
    margin-right: auto;
    border-top-width: $hr-divider-border-width;
    border-bottom-width: $hr-divider-border-width;
    max-width: $hr-divider-max-width;
    height: $hr-divider-height;
    opacity: 0.85;
    &.double-hr {
      border-style: solid;
      height: $hr-divider-double-height;
      max-width: $hr-divider-double-max-width;
      opacity: 0.85;
    }
  }
}

// onekit color 
.text-black-white {
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-black-white-rgb), var(--bs-bg-opacity)) !important;
}
.text-white-black {
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-white-black-rgb), var(--bs-bg-opacity)) !important;
}
.text-light-dark {
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-light-dark-rgb), var(--bs-bg-opacity)) !important;
}
.text-dark-light {
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-text-dark-light), var(--bs-bg-opacity)) !important;
}
.bg-light-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-dark-rgb), var(--bs-bg-opacity)) !important;
}
.card-header {
  --bs-card-border-color: var(--bs-border-light-dark);
}
.form-control {
  border-color: var(--bs-border-light-dark);
}
.card-header-tabs .nav-link {
  border-color: var(--bs-border-light-dark);
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-body-bg);
  border-bottom-color: var(--bs-body-bg);
}

// navbar light to dark color 
.navbar-light-dark, .navbar-dark-light {
  .navbar-nav .show > .nav-link, .navbar-nav .nav-link.active {
    --bs-bg-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
  }
  .nav-item {
    &>.nav-link{
      &.dropdown-toggle {
        &::after {
          background-image: escape-svg($chevron-down-light-dark)
        }
      }
    }
  }
  .dropdown-menu li a.dropdown-toggle::after {
    background-image: escape-svg($chevron-down-light-dark)
  }
  .dropdown-item {
    color: var(--bs-body-color);
    &:hover {
      --bs-bg-opacity: 1;
      color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
    }
  }
  .nav-link {
    color: var(--bs-body-color);
  }
  li:hover {
    .nav-link {
      --bs-bg-opacity: 1;
      color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
    }
  }
}
.dark-to-light, .light-to-dark {
  .dropdown-menu li a.dropdown-toggle::after {
    background-image: escape-svg($chevron-down-light-dark)
  }
  .dropdown-item {
    color: var(--bs-body-color);
    &:hover {
      --bs-bg-opacity: 1;
      color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
    }
  }
}