/*------------------------------------
  Waves
------------------------------------*/
.waves{
  &-top-center {
    position: absolute;
    top:-1px;
    left:0;
    right:0;
    bottom:auto;
  }
  &-bottom-center {
    position: absolute;
    bottom:-1px;
    left:0;
    right:0;
    top:auto;
  }
  &-top-start {
    position: absolute;
    top:-1px;
    left:0;
  }
  &-top-end {
    position: absolute;
    top:-1px;
    right:0;
  }
  &-bottom-start {
    position: absolute;
    bottom:-1px;
    left:0;
  }
  &-bottom-end {
    position: absolute;
    bottom:-1px;
    right:0;
  }
}
// translate to top
@for $i from 1 through 5 {
  .translate-top-#{$i}{
    transform: translate(0, -#{$i}rem);
  }
  .translate-bottom-#{$i}{
    transform: translate(0, #{$i}rem);
  }
}
@keyframes animateWave {
  0% {
    transform: scale(1,0);
    -webkit-transform: scale(1,0);
  }
  100% {
    transform: scale(1,1);
    -webkit-transform: scale(1,1);
  }
}
.waves-animate > svg {
  display: block;
  transform-origin: bottom;
  animation: animateWave 3s cubic-bezier(0.23, 1, 0.32, 1) forwards;
  -webkit-animation: animateWave 3s cubic-bezier(0.23, 1, 0.32, 1) forwards;
}