/*------------------------------------
	Back to top
------------------------------------*/
.back-top {
	visibility: hidden;
	border-radius: $border-radius;
	opacity: $opacity-0;
	transition: $backtotop-transition-1;
	&.backtop-is-visible {
		transition: $backtotop-transition-2;
		z-index: 99;
		visibility: visible;
		opacity: $opacity-1;
	}
	&.backtop-fade-out {
		transition: $backtotop-transition-2;
		opacity: $opacity-08;
	}
}
.no-touch {
	.back-top {
		&:hover {
			transition: $backtotop-transition-2;
			--bs-bg-opacity: 1;
			background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
			opacity: $opacity-1;
		}
	}
}