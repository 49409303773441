/*------------------------------------
	Responsive maps
------------------------------------*/
.responsive-maps {
	position: relative;
	padding-bottom: $maps-padding-bottom !important;
	overflow: hidden;
	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100% !important;
	}
}