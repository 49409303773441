/*------------------------------------
  Five Grid Column
------------------------------------*/
.col-24 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 20%;
}
.offset-24 {
  margin-left: 20%;
}
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .col#{$infix}-24 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: 20%;
    }
    .offset#{$infix}-24 {
        margin-left: 20%;
    }
  }
}